<template>
  <div class="whoops"></div>
</template>

<script>
export default {
  name: "Whoops",
};
</script>

<style scoped lang="scss">
.whoops {
  background-color: white;
  padding-top: -50px;
  margin-top: 50px;
}
</style>
